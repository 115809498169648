/* eslint-disable prefer-template */
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
	Container,
	Panel,
	PanelGroup,
	Table,
	Divider,
	Button,
	Modal,
	Form,
	FormGroup,
	ControlLabel,
	FormControl,
	Alert,
} from "rsuite";
import { saveAs } from "file-saver";

import { firestore, config } from "../../misc/firebase";
import Chart from "./chart";
import { useProfile } from "../../context/profile.context";

const { Cell, HeaderCell, Column, ColumnGroup } = Table;

const JSONtoCSV = (obj = [{}]) => {
	let csvStr = "Subject,Assignment,IA 1,IA 2,IA 3,Final Marks, Passed\n";
	const format = (element) =>
		element === undefined ? "Not Assigned" : String(element);
	obj.forEach((subject) => {
		csvStr +=
			format(subject.name) +
			"," +
			format(subject.assignmentScored) +
			"," +
			format(subject.internal1) +
			"," +
			format(subject.internal2) +
			"," +
			(subject.lab ? "" : format(subject.internal3)) +
			"," +
			format(subject.finalsScored) +
			"," +
			format(subject.hasPassed) +
			"\n";
	});

	const blob = new Blob([csvStr], { type: "text/plain;charset=utf-8" });

	return blob;
};

const structureDataForTable = (semesterResults = {}) => {
	const { semester, ...subjects } = semesterResults;

	return Object.keys(subjects)
		.map((subject) => {
			const data = subjects[subject];
			return subjects[subject]?.isLab === true
				? {
						name: subject,
						maxSEE: data.finals.max,
						maxCIE: 25,
						minSEE: 35,
						totalMin: 35,
						totalObtained:
							parseInt(data?.finalsInternals, 10) +
							parseInt(data.finals?.scored, 10),
						totalMarksMax:
							parseInt(data.finals.max, 10) +
							parseInt(data.internals.max, 10) +
							parseInt(data.assignment.max, 10),
						obtainedCIE: data?.finalsInternals,
						obtainedSEE: data.finals?.scored,
						lab: true,
						hasPassed:
							data?.finalsInternals + data.finals?.scored > 35
								? "Passed"
								: "Failed",
				  }
				: {
						name: subject,
						maxSEE: data.finals.max,
						maxCIE: 25,
						minSEE: 45,
						totalMin: 45,
						totalObtained:
							parseInt(data?.finalsInternals, 10) +
							parseInt(data.finals?.scored, 10),
						totalMarksMax:
							parseInt(data.finals.max, 10) +
							parseInt(data.internals.max, 10) +
							parseInt(data.assignment.max, 10),
						obtainedCIE: data?.finalsInternals,
						obtainedSEE: data.finals?.scored,
						lab: false,
						hasPassed:
							data?.finalsInternals + data.assignment?.scored > 45
								? "Passed"
								: "Failed",
				  };
		})
		.sort((a, b) => {
			if (a.lab > b.lab) {
				return 1;
			}
			if (b.lab > a.lab) {
				return -1;
			}
			return 0;
		});
};

const StudentPage = () => {
	const { id } = useParams();
	const {
		profile: { student_of: studentOf },
	} = useProfile();

	const [studentData, setStudentData] = useState(null);
	const [results, setResults] = useState(null);
	const [rowData, setRowData] = useState(null);
	const [formValue, setFormValue] = useState(null);
	const [loading, setLoading] = useState(true);

	const onSubmit = useCallback(() => {
		if (Object.keys(formValue).length !== 2) {
			Alert.warning("Fileds not Filled", 4000);
			return;
		}
		firestore.collection("results").doc(id).update(formValue);
		Alert.success("Result Edited", 4000);
		setFormValue(null);
		setRowData(null);
	}, [formValue, id]);

	const onFormChange = useCallback((value) => {
		setFormValue(value);
	}, []);
	useEffect(() => {
		// get student data
		const unSubStudent = firestore
			.collection("users")
			.doc(id)
			.onSnapshot((student) => setStudentData(student.data()));

		// get student results
		let unSubResults = () => {};

		unSubResults = firestore
			.collection("results")
			.doc(id)
			.onSnapshot((result) => {
				try {
					const { uid, ...data } = result.data();
					setResults(data);
					setLoading(false);
				} catch (e) {
					const data = null;
					setLoading(false);
					setResults(data);
				}
			});

		/* eslint-disable no-console */
		console.log(config);
		/* eslint-enable no-console */

		return () => {
			unSubResults();
			unSubStudent();
		};
	}, [id]);

	return (
		<Container>
			{!(studentData && !loading) && <h1>Loading Please Wait</h1>}
			{studentData && (
				<div>
					<Panel shaded bordered bodyFill style={{ display: "inline-block" }}>
						<img
							src={studentData.avatar}
							alt={studentData.name}
							height={260}
							width={260}
						/>
						<p>
							<small>Name : </small> {studentData.name}
						</p>
						<Panel>
							<p>
								<small>Age : </small> {studentData.age}
							</p>
							<p>
								<small>Blood Group : </small> {studentData.blood_group}
							</p>
							<p>
								<small>E-mail : </small> {studentData.email}
							</p>
							<p>
								<small>Registration Number : </small>{" "}
								{studentData.registration_number}
							</p>
							<p>
								<small>Semester : </small> {studentData.semester}
							</p>
						</Panel>
					</Panel>
				</div>
			)}

			{results && (
				<>
					<Divider>Results</Divider>
					<PanelGroup accordion bordered>
						{results &&
							Object.keys(results).map((yearName) =>
								Object.keys(results[yearName]).map((semester) => {
									const data = structureDataForTable(
										results[yearName][semester]
									);
									let maxSEE = 0;
									let obtainedSEE = 0;
									let maxCIE = 0;
									let obtainedCIE = 0;
									let totalMarksMax = 0;
									let totalObtained = 0;
									data.forEach((subject) => {
										maxSEE += parseInt(subject.maxSEE || 0, 10);
										obtainedSEE += parseInt(subject.obtainedSEE || 0, 10);
										maxCIE += parseInt(subject.maxCIE || 0, 10);
										obtainedCIE += parseInt(subject.obtainedCIE || 0, 10);
										totalMarksMax += parseInt(subject.totalMarksMax || 0, 10);
										totalObtained += parseInt(subject.totalObtained || 0, 10);
									});
									return (
										<Panel
											header={
												yearName +
												` Semester ${results[yearName][semester].semester} `
											}
										>
											<PanelGroup accordion>
												<Panel header="Table" defaultExpanded>
													<Table
														headerHeight={80}
														bordered
														cellBordered
														data={data}
														height={500}
														onRowClick={(rData) => setRowData(rData)}
													>
														<Column fixed width={200}>
															<HeaderCell>Subject Name</HeaderCell>
															<Cell dataKey="name" />
														</Column>

														<ColumnGroup header="Semester End Examination (SEE)">
															<Column>
																<HeaderCell>Max. Marks</HeaderCell>
																<Cell>
																	{(r) =>
																		String(r.maxSEE || "Marks not filled")
																	}
																</Cell>
															</Column>
															<Column>
																<HeaderCell>Min. Marks</HeaderCell>
																<Cell>
																	{(r) =>
																		String(r.minSEE || "Marks not filled")
																	}
																</Cell>
															</Column>
															<Column>
																<HeaderCell>Marks Obtained</HeaderCell>
																<Cell>
																	{(r) =>
																		String(r.obtainedSEE || "Marks not filled")
																	}
																</Cell>
															</Column>
														</ColumnGroup>

														<ColumnGroup header="Continuous Internal Evaluation (CIE)">
															<Column>
																<HeaderCell>Max. Marks</HeaderCell>
																<Cell>
																	{(r) =>
																		String(r.maxCIE || "Marks not filled")
																	}
																</Cell>
															</Column>

															<Column>
																<HeaderCell>Max. Obtained</HeaderCell>
																<Cell>
																	{(r) =>
																		String(r.obtainedCIE || "Marks not filled")
																	}
																</Cell>
															</Column>
														</ColumnGroup>

														<ColumnGroup header="Total Marks">
															<Column>
																<HeaderCell>Max. Marks</HeaderCell>
																<Cell>
																	{(r) =>
																		String(
																			r.totalMarksMax || "Marks not filled"
																		)
																	}
																</Cell>
															</Column>
															<Column>
																<HeaderCell>Min. Marks</HeaderCell>
																<Cell>
																	{(r) =>
																		String(r.totalMin || "Marks not filled")
																	}
																</Cell>
															</Column>
															<Column>
																<HeaderCell>Marks Obtained</HeaderCell>
																<Cell>
																	{(r) =>
																		String(
																			r.totalObtained || "Marks not filled"
																		)
																	}
																</Cell>
															</Column>
														</ColumnGroup>
														<Column>
															<HeaderCell>Result</HeaderCell>
															<Cell>
																{(r) =>
																	r.totalObtained > r.totalMin
																		? "Passed"
																		: "Failed"
																}
															</Cell>
														</Column>
													</Table>
													<hr />
													<h4>Grand Total:</h4>
													<p>SEE Max.Marks: {maxSEE}</p>
													<p>SEE Marks Obtained: {obtainedSEE}</p>
													<p>CIE Max.Marks: {maxCIE}</p>
													<p>CIE Marks Obtained: {obtainedCIE}</p>
													<p>Total Max Marks: {totalMarksMax}</p>
													<p>Total Marks Obtained: {totalObtained}</p>
													<hr />
													<Button
														onClick={() => {
															saveAs(
																JSONtoCSV(data),
																`${studentData.name} Year_${yearName} Semester_${semester}.csv`
															);
														}}
													>
														Download the result
													</Button>
												</Panel>
												<Panel header="Chart">
													<Chart data={data} />
												</Panel>
												{rowData && studentOf === false && (
													<Modal
														onHide={() => setRowData(null)}
														show={!!rowData}
													>
														<Modal.Header>
															<Modal.Title>
																Year {yearName}
																{semester === "1"
																	? " Odd Semester"
																	: " Even Semester"}{" "}
															</Modal.Title>
														</Modal.Header>

														<Modal.Body>
															<h4> Subject {rowData.name} </h4>
															<Form
																onChange={onFormChange}
																formValue={formValue}
															>
																<FormGroup>
																	<ControlLabel>Internals :</ControlLabel>

																	<FormControl
																		name={`${yearName}.${semester}.${rowData.name}.finalsInternals`}
																		className="mt-1"
																	/>
																</FormGroup>
																<FormGroup>
																	<ControlLabel>Final Exam :</ControlLabel>
																	<FormControl
																		name={`${yearName}.${semester}.${rowData.name}.finals.scored`}
																	/>
																</FormGroup>
															</Form>
														</Modal.Body>
														<Modal.Footer>
															<Button onClick={onSubmit}>Submit Edit</Button>
														</Modal.Footer>
													</Modal>
												)}
											</PanelGroup>
										</Panel>
									);
								})
							)}
					</PanelGroup>
				</>
			)}
		</Container>
	);
};

export default StudentPage;
