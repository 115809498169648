import React from "react";
import { Modal } from "rsuite";
import { useModalState } from "../../../misc/custom-hooks";

const ImgBtnModal = ({ src, fileName }) => {
	const { isOpen, open, close } = useModalState();

	return (
		<>
			<input
				type="image"
				src={src}
				alt="file"
				onClick={open}
				style={{ maxWidth: 100, maxHeight: 100 }}
				className="mw-100 mh-100 w-auto"
			/>
			<Modal show={isOpen} onHide={close}>
				<Modal.Header>
					<Modal.Title>{fileName}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<img src={src} height="100%" width="100%" alt="file" />
					</div>
				</Modal.Body>
				<Modal.Footer>
					<a href={src} target="_blank" rel="noopener noreferrer">
						View original
					</a>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ImgBtnModal;
